.layoutContainer {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.childrenContainer {
  margin-top: 100px;
  /* margin-left: 100px;
  margin-right: 100px; */
  /* background-color: aqua; */
}

.childrenContainerNoMargin {
  height: 100%;
}

.defaultChildrenContainer {
  margin-top: 100px;
  /* background-color: aqua; */
}

.scrollUp {
  position: fixed;
  bottom: 140px;
  right: 30px;
  animation: appear 0.5s;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.scrollUpSmall {
  position: fixed;
  bottom: 60px;
  right: 30px;
  display: none;
}

.bottomInfos {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 99;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #000;
  opacity: 0.9;
  color: #fff;
  animation: up 0.25s;
}

@keyframes up {
  from {
    bottom: -50px;
  }

  to {
    bottom: 0px;
  }
}

.menuSpace {
  height: 100px;
  width: 100%;
}
